<template>
    <div class="container-fluid">
        <div class="block-header">
                <bread-crumb></bread-crumb>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <weather-carousel></weather-carousel>
            </div>
            <div class="col-lg-4 col-md-6">
                <weather-city></weather-city>
            </div>
            <div class="col-lg-4 col-md-6">
                <weather-clear-night></weather-clear-night>
            </div>
            <div class="col-lg-6 col-md-12">
                <weather-sky ></weather-sky>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'WeatherComponent',
    components: {
        BreadCrumb: () => import('@/components/BreadCrumb.vue'),
        'weather-carousel': () => import('@/components/core/Weather1.vue'),
        'weather-city': () => import('@/components/core/Weather2.vue'),
        'weather-clear-night': () => import('@/components/core/Weather3.vue'),
        'weather-sky': () => import('@/components/core/Weather4.vue'),
    }
}
</script>
